import PageContainer from '../../components/Charge/PageContainer'
import { ReactComponent as Logo } from '../../images/logo.svg'
import OnboardingSteps from '../../components/OnboardingSteps'
import Settings from '../Settings/Settings'

function Header() {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Logo />
    <OnboardingSteps current={2} />
  </div>
}

function OnboardingSettings ({ shop, updateShop }) {
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<Settings shop={shop} updateShop={updateShop} onboarding />}
    />
  )
}

export default OnboardingSettings
