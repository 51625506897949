import { Table, Space, Button } from 'antd'
import { useEffect, useMemo } from 'react'
import { useFetch } from '../../hooks/requests'
import moment from 'moment'

function Invoices() {
  const [request, { loading, /*  error, */ data }] = useFetch()

  useEffect(() => {
    (async () => {
      await request(
        'billing/invoices',
        'GET',
      )
    })()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataSource = useMemo(() => {
    return data?.invoices?.data?.map((item) => ({
      id: item.id,
      status: item.status,
      date: moment(item.created * 1000).format('D MMM YYYY'), // finalized_at // '28 May 2022',
      source: 'Your Site',
      amount: `$${item.total / 100}`,
      invoice_pdf: item.invoice_pdf,
      hosted_invoice_url: item.hosted_invoice_url,
    }))
  }, [data])

  const columns = [
    {
      title: 'Date of the invoice',
      dataIndex: 'date',
      key: 'date',
      render: (text) => text,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (text) => text,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => text,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => text,
    },
    {
      title: '',
      source: 'id',
      key: 'id',
      render: (_id, item) => <Space size="middle">
        {item.invoice_pdf && <Button type="primary" href={item.invoice_pdf}>Download PDF</Button>}
        {item.hosted_invoice_url && <Button type="primary" href={item.hosted_invoice_url} target="_blank" >View </Button>}
      </Space>,
    },
  ]

  return <div>
    <div className="text-16 font-weight-500" style={{ marginBottom: '20px' }}>Invoices</div>
    <Table dataSource={dataSource} columns={columns} loading={loading} />
  </div>
}

export default Invoices
