import './App.less'
import './App.scss'
import useToken from './hooks/useToken'
import { Provider as SessionProvider } from './context/main'
// import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import RouterComponent from './Router'
import ErrorBoundary from './containers/ErrorBoundary'
// import { getLocation } from './lib/getLocation'
import { FakeHistoryProvider } from './context/fakeHistoryContext'
import TokenFailed from './containers/TokenFailed'

function App() {
  const { error, token } = useToken()

  // const urlParams = new URLSearchParams(getLocation().search)

  // const host = urlParams.get('host') || process.env.REACT_APP_DEBUG_HOST

  if (error) {
    if (error?.code === 401) {
      return <TokenFailed />
    } else {
      return <div>error: 14600</div>
    }
  }

  if (!token) return <div>Loading...</div>

  return (
    <div className="App">
      <SessionProvider token={token}>
        <Router><FakeHistoryProvider>
          <ErrorBoundary>
            {/* <Suspense fallback={<div>Loading</div>} > */}
            <RouterComponent />
            {/* </Suspense> */}
          </ErrorBoundary>
        </FakeHistoryProvider></Router>
      </SessionProvider>
    </div>
  )
}

export default App
