import { Alert, Button } from 'antd'
import { useContext } from 'react'
import DebugContext from '../context/DebugContext'

function ResetBlock() {
  const debugContext = useContext(DebugContext)
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Reset all changes"
    action={
      <Button
        size="large"
        type="default"
        onClick={() => {
          debugContext.reset()
        }}
      >
        Reset
      </Button>
    }
  />
}

export default ResetBlock
