import useCustomHistory from '../hooks/useCustomHistory'
import { Menu } from 'antd'
import useCustomLocation from '../hooks/useCustomLocation'

function TabMenu(props) {
  const { tabs } = props
  const history = useCustomHistory()
  const { pathname: selected } = useCustomLocation()

  const onClick = (event) => {
    // console.log('click ', event.key, event.item?.props?.external)
    if (event.item?.props?.external === 'yes') {
      return
    }
    history.push(event.key)
  }

  return <Menu
    onClick={onClick}
    selectedKeys={[selected]}
    mode="horizontal"
    items={tabs}
  />

}

export default TabMenu
