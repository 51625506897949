import { useHistory } from 'react-router-dom'

function useCustomHistory() {
  let history = useHistory()
  return {
    push: (path) => {
      if (process.env.REACT_APP_HISTORY_MODE === 'query') {
        const url = new URL(window.location.href)
        if (path === '/') {
          url.searchParams.delete('route')
        } else {
          url.searchParams.set('route', path)
        }
        history.push(url.pathname + url.search.replace(/%2F/g, '/'))
      } else {
        history.push(path)
      }
    },
  }
}

export default useCustomHistory
