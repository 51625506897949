import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import './SpinnerWithMessage.scss'

const SpinnerWithMessage = ({ isLoading, message }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(()=>{
    if (isLoading) {
      setIsOpen(isLoading)
      document.body.style.overflow = 'hidden'
    }
    else {
      setTimeout(()=>{
        setIsOpen(isLoading)
        document.body.style.overflow = 'unset'
      }, 1000)
    }
  }, [isLoading])
  return (
    <>
      {isOpen && (
        <div className="spinnerWithMessage-background">
          <div className="spinnerWithMessage-body">
            {isLoading && <Spin size="large" />}
            {!isLoading && <span className="message">{message}</span>}
          </div>
        </div>
      )}
    </>
  )
}

export default SpinnerWithMessage
