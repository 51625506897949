import { useEffect, useState } from 'react'
// import createApp from '@shopify/app-bridge'
// import { Redirect } from '@shopify/app-bridge/actions'
// import { getSessionToken } from '@shopify/app-bridge-utils'
// import { getLocation } from '../lib/getLocation'

const unauthorized = {
  message: 'no token',
  code: 401,
}

async function verifyToken(shopOrigin, token, authType = '') {
  console.log('TCL ~ file: useToken.js ~ line 13 ~ verifyToken ~ shopOrigin, token, authType', shopOrigin, token, authType)
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API_URL}verify_token?shop=${shopOrigin}&token=${token}`,
      {
        headers: {
          'x-auth-type': authType,
        },
      },
    )
    console.log('verifyToken response status:', resp.status)
    if (resp.ok) {
      const data = await resp.json()
      console.log('verifyToken response data:', data)
      if (data && data.status === 'ok') {
        return true
      }
    }
  } catch (err) {
    console.log('verifyToken request error:', err)
  }
  return false
}

function useToken() {
  const [shopOrigin, shopOriginSet] = useState(null)
  const [error, errorSet] = useState(false)
  const [token, tokenSet] = useState(null)

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN) {
      shopOriginSet(process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN)
      tokenSet('DEBUG')
      return
    }
    // wordpress
    if (typeof window.xCompliantToken !== 'undefined' && window.xCompliantDomain) {
      if (!window.xCompliantToken) {
        return errorSet(unauthorized)
      }
      (async () => {
        if (await verifyToken(window.xCompliantDomain, window.xCompliantToken, 'wordpress')) {
          shopOriginSet(window.xCompliantDomain)
          tokenSet(window.xCompliantToken)
        } else {
          return errorSet(unauthorized)
        }
      })()
      return
    }
    return errorSet(unauthorized)
    // TODO
    /*
    const url = new URL(getLocation().href)
    const _shopOrigin = shopOrigin || url.searchParams.get('shop')
    const _host = url.searchParams.get('host')
    if (!_shopOrigin) {
      errorSet(unauthorized)
      return
    }
    (async () => {
      // let lToken = null
      if (window.top === window.self) {
        window.location.assign(`/auth?shop=${_shopOrigin}`)
      } else {
        if (!_host) {
          errorSet(true)
          return
        }
        // const app = createApp({
        //   apiKey: process.env.REACT_APP_API_KEY,
        //   host: _host,
        // //   shopOrigin: _shopOrigin,
        // })
        // function redirect() {
        //   Redirect.create(app).dispatch(Redirect.Action.REMOTE, `${url.protocol}//${url.host}/auth?shop=${_shopOrigin}`)
        // }
        // get session token
        // lToken = await getSessionToken(app)
        // if (!lToken) {
        //   redirect()
        // } else {
        //   if (await verifyToken(_shopOrigin, lToken)) {
        //     shopOriginSet(_shopOrigin)
        //     tokenSet(lToken)
        //   } else {
        //     redirect()
        //   }
        // }
      }
    })()
    */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { shopOrigin, error, token }
}

export default useToken
