import { Button, Input } from 'antd'
import React, { useCallback, useState } from 'react'
import PageContainer from '../components/Charge/PageContainer'

const WaitingPage = ({ shop, skip, updateEmail }) => {

  const [email, setEmail] = useState('')

  const handleEmailChange = useCallback((newValue) => {
    setEmail(newValue)
  }, [])

  const thirdPage = {
    leftBlock: (
      <>
        <img alt="" source="/images/accessibilty-logo.svg" />
        <div className="text-36">
          Our team is analyzing
          your site.
        </div>
        <div className="text-24">
          {'This usually is very fast (<2 hours) but can take up to 12 hours in peak demand time.'}
        </div>
        <div className="text-14">
          <span>
            Once your integration is ready to be enabled, you'll receive a notification by email at
          </span>
          <span className="bold-text">
            {' '}{shop.email}
          </span>
        </div>
        <div className="text-14">
          <span>No worries, you'll be able to customize your settings</span>
          <span className="bold-text">
            {' before '}
          </span>
          <span>putting it live on your site.</span>
        </div>
      </>
    ),
    rightBlock: (<img alt="" source="/images/third-page.svg" />),
    footer: {
      img: (<img alt="" source="/images/third-page-footer.svg" />),
      content: (
        <>
          <div className="text-16">
            Receive a notification when our analysis is complete
          </div>
          <div className="footer-input">
            <div className="text-12">
              Would you like to be notified on another email than {shop.email}?
            </div>
            <Input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Your other email"
            />
          </div>
        </>
      ),
      btn: (
        <div>
          <Button
            disabled={!email}
            onClick={() => {
              updateEmail(email)
              setEmail('')
            }}
          >
            Save
          </Button>
        </div>
      ),
    },
  }

  return (
    <PageContainer
      leftBlock={thirdPage.leftBlock}
      rightBlock={thirdPage.rightBlock}
      footer={thirdPage.footer}
    />
  )
}

export default WaitingPage
