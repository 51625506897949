import { useState } from 'react'
import log from '../lib/log'

export function useFetch() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [resData, setResData] = useState(null)

  async function request(url, method = 'GET', data, fakeResult) {
    setLoading(true)
    if (fakeResult) {
      setResData(fakeResult)
      setLoading(false)
      return fakeResult
    }
    try {
      const options = {
        method: method || 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      if (data) {
        options.body = JSON.stringify(data)
      }
      let lToken = null
      if (process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN) {
        lToken = 'DEBUG'
        options.headers['x-shop-name'] = process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN
        options.headers['x-auth-type'] = process.env.REACT_APP_DEBUG_AUTH_TYPE || ''
        if (process.env.REACT_APP_DEBUG_FRONTEND_URL) {
          options.headers['x-frontend-url'] = process.env.REACT_APP_DEBUG_FRONTEND_URL
          window.document.cookie = `frontendUrl=${process.env.REACT_APP_DEBUG_FRONTEND_URL}; path=/`
        }
      } else if (typeof window.xCompliantToken !== 'undefined') {
        lToken = window.xCompliantToken
        options.headers['x-auth-type'] = 'wordpress'
      } else {
        lToken = 'TODO'
      }
      if (!lToken) {
        return setError({
          message: 'no token',
          code: 401,
        })
      }
      if (lToken) {
        options.headers.Authorization = `Bearer ${lToken}`
      }

      const resp = await fetch(process.env.REACT_APP_API_URL + url, options)
      let result = null
      try {
        const respData = await resp.json()
        if (resp.status === 200) {
          result = respData
          setResData(respData)
        } else {
          setError({
            message: respData.message || 'unknown message',
            code: resp.status,
          })
          log.error('request', respData.message || 'unknown message', { url, method, responseStatus: resp.status })
        }
      } catch (err) {
        setError({ message: 'unknown error', code: 601 })
        log.error('request', err, { url, method, status: 601, datails: 'Parse response error' })
      }
      setLoading(false)
      return result
    } catch (err) {
      setError({ message: 'Unknown', code: 602 })
      log.error('request', err, { url, method, status: 602 })
    }
  };
  return [request, { loading, error, data: resData }]
}

export function useGetShop() {
  const [request, { loading, error, data }] = useFetch()
  return [
    () => {
      return request(
        'default/shop',
        'GET',
        undefined,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}

export function useUpdateShop() {
  const [request, { loading, error, data }] = useFetch()
  return [
    (data) => {
      return request(
        'default/updateShop',
        'POST',
        data,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}
