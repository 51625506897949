import PageContainer from '../../components/Charge/PageContainer'
// import { ReactComponent as PageIllustration } from '../../images/onboarding-payment.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import OnboardingSteps from '../../components/OnboardingSteps'
import PricingPlans from '../PricingPlans'

function Header() {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Logo />
    <OnboardingSteps current={1} />
  </div>
}

function PlanSelection ({ primaryAction, shop, updateShop }) {

  return (
    <PageContainer
      header={<Header />}
      leftBlock={<>
        <PricingPlans primaryAction={primaryAction} shop={shop} updateShop={updateShop} onboarding />
      </>}
      // rightBlock={(<PageIllustration />)}
    />
  )
}

export default PlanSelection
