import { Button, Skeleton } from 'antd'
import { ReactComponent as PageIllustration } from '../../images/payment-method.svg'
import { ReactComponent as MastercardIcon } from '../../images/mastercard.svg'
import { ReactComponent as VisaIcon } from '../../images/visa.svg'
import { useCallback, useEffect } from 'react'
import { useFetch } from '../../hooks/requests'

function PaymentMethod({ shop }) {
  const [request, { loading, /*  error, */ data }] = useFetch()
  const [actionRequest, { loading: actionLoading }] = useFetch()

  useEffect(() => {
    (async () => {
      await request(
        'billing/paymentMethod',
        'GET',
      )
    })()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePaymentMethod = useCallback(async () => {
    const result = await actionRequest(
      'default/createCheckoutSession',
      'POST',
      {
        action: 'change_payment_method',
      },
    )
    console.log('ChangePaymentMethod ~ result', result)
    if (result.url) {
      window.location.href = result.url
    } else {
      window.location.reload()
    }
  }, [actionRequest])

  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div className="rows rows-align-left">
      <div  className="text-16 bold-text">Payment Method</div>
      <div
        style={{
          width: '423px',
          height: '222px',
          background: '#FFFFFF',
          border: '2px solid #096DD9',
          borderRadius: '10px',
          padding: '18px 30px',
        }}
      >
        {loading && <Skeleton active />}
        {!loading && <>
          <div className="text-16" style={{ marginBottom: '104px' }}>
            <span style={{ textTransform: 'capitalize' }}>{data?.funding}</span> Card
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '2em' }}>
            {(data?.brand === 'mastercard') && <MastercardIcon />}
            {(data?.brand === 'visa') && <VisaIcon />}
            <div className="text-16" style={{ fontSize: '20px' }}>**** **** **** {data?.last4}</div>
          </div>
        </>}
      </div>
      {shop.platform === 'wordpress' &&
        <Button loading={actionLoading} type="primary" onClick={() => handleChangePaymentMethod()}>Change payment method</Button>
      }
    </div>
    <PageIllustration style={{ paddingRight: '60px' }} />
  </div>
}

export default PaymentMethod
