import React from 'react'
import './PageContainer.scss'
import { ReactComponent as PageBgIllustration } from '../../images/bg.svg'

const PageContainer = ({ header = null, leftBlock, rightBlock, footer }) => {
  return (
    <div className="xcompliant-page">
      {!!header && <div className="page-header">{header}</div>}
      <div className="page-body">
        <PageBgIllustration
          style={{
            position: 'absolute',
            height: '600px',
            zIndex: '-1',
          }}
        />
        <>
          <div className="left-block">{leftBlock}</div>
          {!!rightBlock && <div className="right-block">{rightBlock}</div>}
        </>
      </div>
      {footer && (
        <div className="page-footer">
          <div className="footer-left">{footer.img}</div>
          <div className="footer-right">
            {footer.content}
            <div className="footer-btn">{footer.btn}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PageContainer
