import { useState, createContext } from 'react'

const FakeHistoryContext = createContext({
  token: null,
  setSession: (data) => {},
})

export const FakeHistoryProvider = (props) => {
  const [location, setLocation] = useState({ pathname: '/' })
  return <FakeHistoryContext.Provider
    value={{
      location,
      setLocation,
      // setSession: (data) => {setSession(data)}
    }}
  >
    {props.children}
  </FakeHistoryContext.Provider>
}

export default FakeHistoryContext
