import { Button } from 'antd'
import React from 'react'
import PageContainer from '../components/Charge/PageContainer'

const SubscriptionIssue = ({ skip }) => {
  const secondPage = {
    leftBlock: (
      <>
        <img alt="" source="/images/accessibilty-logo.svg" />
        <div className="text-36">
          There's currently a problem with your application subscription
        </div>
        <div className="text-24">
          Please contact support.
        </div>
        <div>
          <Button type="primary" url={process.env.REACT_APP_CONTACT_US_URL} target="_blank">
            Contact support
          </Button>
        </div>
      </>
    ),
    rightBlock: (<img alt="" source="/images/second-page.svg" />),
  }

  return (
    <PageContainer
      leftBlock={secondPage.leftBlock}
      rightBlock={secondPage.rightBlock}
    />
  )
}

export default SubscriptionIssue
