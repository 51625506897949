// const log = console
import { getLocation } from './getLocation'
import rollbar from './rollbar'

function isPlainObject(obj) {
  return  typeof obj === 'object' // separate from primitives
    && obj !== null         // is obvious
    && obj.constructor === Object // separate instances (Array, DOM, ...)
    && Object.prototype.toString.call(obj) === '[object Object]' // separate build-in like Math
}

const log = {
  _log(level, ...args) {
    let err = args[1]
    let data = {}
    function addItemToData(_data) {
      if (typeof _data !== 'undefined' && _data !== null) {
        if (isPlainObject(_data)) {
          try {
            data = {
              ...data,
              ..._data,
            }
          } catch (__) {
            //
          }
        } else {
          if (!Array.isArray(data._plain_items)) {
            data._plain_items = []
          }
          data._plain_items.push(_data)
        }
      }
    }
    if (!(err instanceof Error)) {
      if (typeof err === 'string') {
        err = new Error(err)
      } else {
        err = new Error('Error')
        addItemToData(args[1])
      }
    }
    for (let ind = 2; ind < args.length; ind++) {
      addItemToData(args[ind])
    }
    let context = ''
    let searchString = ''
    try {
      context = getLocation().pathname
      searchString = getLocation().search
    } catch (__) {
      //
    }
    rollbar.log(err, { type: args[0], level, context, payload: data, searchString })
  },
  error(...args) {
    this._log.apply(this, ['error', ...args])
  },
  log(...args) {
    this._log.apply(this, ['info', ...args])
  },
  info(...args) {
    this._log.apply(this, ['info', ...args])
  },
  warn(...args) {
    this._log.apply(this, ['warn', ...args])
  },
  debug(...args) {
    this._log.apply(this, ['debug', ...args])
  },
}
export default log
