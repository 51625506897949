import { Steps } from 'antd'
import React from 'react'

const { Step } = Steps

const OnboardingSteps = ({ current }) => (
  <Steps current={current} style={{ maxWidth: '858px' }} >
    <Step title="Domain" />
    <Step title="Plan selection" />
    <Step title="Settings" />
    <Step title="Analyze your site" />
  </Steps>
)

export default OnboardingSteps
