import React, { useState } from 'react'

const SessionContext = React.createContext({
  token: null,
  setSession: (data) => {},
})

export const Provider = (props) => {
  const [token, setSession] = useState(props.token)
  return <SessionContext.Provider
    value={{
      token,
      setSession,
      // setSession: (data) => {setSession(data)}
    }}
  >
    {props.children}
  </SessionContext.Provider>
}

export default SessionContext
