import Rollbar from 'rollbar'

const rollbar = new Rollbar({
  accessToken: '60d1916467984d62ae489d1847ba54ef',
  captureUncaught: true,
  enabled: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
})

export default rollbar
