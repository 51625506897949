import { useEffect } from 'react'
import { useFetch, useGetShop } from './requests'

export function useChangePlan() {
  const [request, { loading, error, data }] = useFetch()
  const [getShop] = useGetShop()

  useEffect(() => {
    if (data) {
      if (data.confirmationURL) {
        window.location = data.confirmationURL
      } else {
        getShop()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return [
    (planName, annual = false) => {
      return request(
        `default/createCharge?name=${planName}&annual=${annual ? 'true' : 'false'}`,
        'GET',
        undefined,
      )
    },
    { loading, error, data },
  ]

}
