let isDebugMode = false

const getCookieValue = (name) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export function setDebug(_isDebugMode) {
  isDebugMode = _isDebugMode
}

export function isDebug() {
  console.log('TCL ~ file: debug.js ~ line 12 ~ isDebug ~ isDebug', isDebugMode)
  return isDebugMode
}

export function initDebug() {
  const url = new URL(window.location.href)
  const debugFromUrl = url.searchParams.get('debug')
  if (['0', '1'].includes(debugFromUrl)) {
    document.cookie = `xcompliant_debug=${debugFromUrl}; path=/; max-age=${30 * 24 * 60 * 60}`
    isDebugMode = debugFromUrl === '1'
  } else {
    const debugFromCookie = getCookieValue('xcompliant_debug')
    isDebugMode = debugFromCookie === '1'
  }
}
