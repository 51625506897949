import Agreement from './Agreement'
import Analyze from './Analyze'
import PlanSelection from './PlanSelection'
import OnboardingSettings from './OnboardingSettings'
import VerifyDomain from './VerifyDomain'
import Welcome from './Welcome'

function Onboarding ({ shop, updateShop }) {
  const { onboardingPassedStage } = shop

  if (onboardingPassedStage === 'settings') {
    return <Analyze
      shop={shop}
      primaryAction={() => {
        updateShop({
          onboardingPassedStage: 'analyze',
          // TODO: it should be change by backend
          setupCompleted: true,
        })
      }}
    />
  }
  if (onboardingPassedStage === 'agreement') {
    return <OnboardingSettings shop={shop} updateShop={updateShop} />
  }
  if (onboardingPassedStage === 'payment') {
    return <Agreement primaryAction={() => {updateShop({ onboardingPassedStage: 'agreement' })}} />
  }
  if (onboardingPassedStage === 'domain') {
    return <PlanSelection shop={shop} updateShop={updateShop} />
  }
  if (onboardingPassedStage === 'welcome') {
    return <VerifyDomain shop={shop} primaryAction={() => {updateShop({ onboardingPassedStage: 'domain' })}} />
  }
  return <Welcome primaryAction={() => {updateShop({ onboardingPassedStage: 'welcome' })}} />
}

export default Onboarding
