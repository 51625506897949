import { useLocation } from 'react-router-dom'

function useCustomLocation() {
  const location = useLocation()

  if (process.env.REACT_APP_HISTORY_MODE === 'query') {
    const searchParams = new URLSearchParams(location.search)
    location.pathname = searchParams.get('route') || '/'
    return location
  } else {
    return location
  }

}

export default useCustomLocation
